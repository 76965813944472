/* eslint-disable @typescript-eslint/no-misused-promises */
import { Box, Checkbox, FormControlLabel, FormGroup } from '@mui/material'
import { useUser } from '@trr/app-shell-data'
import React from 'react'
import { useForm } from 'react-hook-form'
import { BaseForm, CopyLinkDialog } from 'src/components'
import { usePostArbetsgivarinformationMutation } from 'src/services'
import { FormValues } from 'src/types'
import { getArbetsgivarinfoData, getArbetsgivarinfoUrl } from 'src/utils'

const CreateLinkForm = () => {
  const formProps = useForm<FormValues>({
    defaultValues: {
      text: '',
      startTime: null,
      endTime: null,
      name: '',
    },
  })

  const [postArbetsgivarinfo, result] = usePostArbetsgivarinformationMutation()

  const user = useUser()

  const onSubmit = async (data: FormValues) => {
    const arbetsgivarinfo = getArbetsgivarinfoData(
      data,
      user?.kundProperties?.foretag.organizationNumber ?? ''
    )
    await postArbetsgivarinfo(arbetsgivarinfo)
  }

  return (
    <Box
      component="form"
      onSubmit={formProps.handleSubmit(onSubmit)}
      data-testid="create-link-form"
    >
      <BaseForm
        isError={result.isError}
        formProps={formProps}
        submitText="Skapa länk"
        CheckBoxes={
          <FormGroup sx={{ mb: 3 }}>
            <FormControlLabel
              control={<Checkbox required />}
              label="Parterna är överens om att söka TRRs stöd"
            />
            <FormControlLabel
              control={<Checkbox required />}
              label="Jag godkänner villkoren för stöd vid risk för omställning"
            />
          </FormGroup>
        }
      />
      {result.isSuccess && (
        <CopyLinkDialog
          open={result.isSuccess}
          arbetsgivarinfoUrl={getArbetsgivarinfoUrl(result.data.id)}
        />
      )}
    </Box>
  )
}

export default CreateLinkForm
