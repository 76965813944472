import { ArbetsgivarinfoCreate, FormValues } from 'src/types'

const getArbetsgivarinfoData = (
  formValues: FormValues,
  orgNr: string
): ArbetsgivarinfoCreate => {
  return {
    ...formValues,
    // Fields are validated in react-hook-form so we can safely assume these are not null
    startTime: formValues.startTime?.toISOString() ?? null,
    endTime: formValues.endTime?.toISOString() ?? null,
    organizationNumber: orgNr ?? '',
    isEnabled: true,
  }
}

export default getArbetsgivarinfoData
