import React from 'react'
import { AppShellDataProps, AppShellDataProvider } from '@trr/app-shell-data'
import { defaultLocale, themeOptions } from '@trr/mui-theme'
import { createTheme, ThemeProvider } from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3'
import { sv } from 'date-fns/locale/sv'
import { ApiProvider } from '@reduxjs/toolkit/dist/query/react'
import App from 'App'
import { api } from './services'

type Content = {
  heading: string
}

const Index = ({
  appShellData,
}: {
  appShellData: AppShellDataProps<Content>
}) => {
  const theme = createTheme(themeOptions, defaultLocale, sv)
  return (
    <ThemeProvider theme={theme}>
      <AppShellDataProvider value={appShellData}>
        <ApiProvider api={api}>
          <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={sv}>
            <App />
          </LocalizationProvider>
        </ApiProvider>
      </AppShellDataProvider>
    </ThemeProvider>
  )
}

export default Index
