import Check from '@mui/icons-material/Check'
import Edit from '@mui/icons-material/Edit'
import Link from '@mui/icons-material/Link'
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  ListItemText,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material'
import { date } from '@trr/frontend-datetime'

import React from 'react'
import { getArbetsgivarinfoUrl } from 'src/utils'

interface LinkDetailsCardProps {
  title: string
  visits: number
  linkId: string
  startDate: string
  endDate: string
  isEnabled?: boolean
}

const LinkDetailsCard = (props: LinkDetailsCardProps) => {
  const { title, visits, startDate, endDate, isEnabled = true, linkId } = props
  const arbetsgivarinfoUrl = getArbetsgivarinfoUrl(linkId)
  const [open, setOpen] = React.useState(false)
  const [isLinkCopied, setIsLinkCopied] = React.useState(false)

  const handleOpenLinkDialog = () => {
    setOpen(true)
  }

  const handleCloseLinkDialog = () => {
    setOpen(false)
    setIsLinkCopied(false)
  }

  const copyLinkToClipboard = () => {
    navigator.clipboard
      .writeText(arbetsgivarinfoUrl)
      .then(() => {
        setIsLinkCopied(true)
      })
      .catch(() => setIsLinkCopied(false))
  }

  const editLinkUrl = `/trr-foretag/dina-lankar/uppdatera?linkId=${linkId}`

  return (
    <>
      <Card
        sx={{ maxWidth: '650px', width: '100%', mb: 2 }}
        data-testid={isEnabled ? 'active-link' : 'inactive-link'}
      >
        <CardHeader title={title} />
        <CardContent>
          <Box
            display="flex"
            mb={3}
            sx={{ flexDirection: { xs: 'column', sm: 'row' } }}
            gap={{ xs: 0, lg: 5 }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'flex-end',
                gap: 1,
                backgroundColor: isEnabled ? 'surface.green' : 'surface.grey',
                width: 220,
                height: 71,
                borderRadius: 1,
                padding: '13px 16px',
              }}
            >
              <Typography variant="h3">{visits}</Typography>
              <Typography
                variant="body2"
                sx={{ transform: 'translate(0, -8px)' }}
              >
                besök
              </Typography>
            </Box>
            <Box display="flex" flexGrow={1}>
              <Divider orientation="vertical" />
              <ListItemText
                sx={{ paddingLeft: 4, paddingRight: 2 }}
                primary={date.format(startDate, { year: true })}
                secondary="Startdatum"
              />
              <Divider orientation="vertical" />
              <ListItemText
                sx={{ paddingLeft: 4, paddingRight: 2 }}
                primary={date.format(endDate, { year: true })}
                secondary="Slutdatum"
              />
            </Box>
          </Box>
        </CardContent>
        <CardActions>
          <Button
            data-testid="edit-link-button"
            startIcon={<Edit />}
            size="small"
            variant="text"
            href={editLinkUrl}
          >
            Redigera
          </Button>
          <Button
            startIcon={<Link />}
            size="small"
            variant="text"
            onClick={handleOpenLinkDialog}
            disabled={!isEnabled}
            data-testid="open-link-copy-dialog-button"
          >
            Kopiera länk
          </Button>
        </CardActions>
      </Card>
      <Dialog open={open} fullWidth>
        <DialogTitle>Kopiera länk</DialogTitle>
        <DialogContent>
          <TextField
            InputProps={{
              startAdornment: <Link />,
              endAdornment: isLinkCopied ? (
                <Box display="flex">
                  <Check />
                  <Typography variant="body2" sx={{ color: 'success.main' }}>
                    Kopierad
                  </Typography>
                </Box>
              ) : (
                <Button
                  variant="text"
                  size="small"
                  onClick={copyLinkToClipboard}
                  data-testid="copy-link-button"
                >
                  Kopiera
                </Button>
              ),
            }}
            defaultValue={arbetsgivarinfoUrl}
            contentEditable={false}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button variant="text" size="small" onClick={handleCloseLinkDialog}>
            Stäng
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default LinkDetailsCard
