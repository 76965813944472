import React from 'react'

const ValidatedLinkImage = () => {
  return (
    <svg
      width="500"
      height="500"
      viewBox="0 0 500 500"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M311.21 106.603C320.964 106.603 328.872 98.6954 328.872 88.9411C328.872 79.1867 320.964 71.2793 311.21 71.2793C301.456 71.2793 293.548 79.1867 293.548 88.9411C293.548 98.6954 301.456 106.603 311.21 106.603Z"
        fill="#B5EDE9"
      />
      <path
        d="M371.026 105.877C376.338 105.877 380.645 101.57 380.645 96.2581C380.645 90.9459 376.338 86.6395 371.026 86.6395C365.714 86.6395 361.408 90.9459 361.408 96.2581C361.408 101.57 365.714 105.877 371.026 105.877Z"
        fill="#FFC700"
      />
      <path
        d="M434.682 146.259C434.682 149.077 432.399 151.36 429.58 151.36C426.762 151.36 424.479 149.077 424.479 146.259C424.479 143.44 426.762 141.157 429.58 141.157C432.399 141.157 434.682 143.44 434.682 146.259Z"
        fill="#B5EDE9"
      />
      <path
        d="M247.763 166.524C247.763 169.342 245.48 171.625 242.662 171.625C239.843 171.625 237.56 169.342 237.56 166.524C237.56 163.705 239.843 161.422 242.662 161.422C245.48 161.422 247.763 163.705 247.763 166.524Z"
        fill="#B5EDE9"
      />
      <path
        d="M500 186.499C500 193.779 494.098 199.687 486.812 199.687C479.526 199.687 473.624 193.785 473.624 186.499C473.624 179.213 479.526 173.311 486.812 173.311C494.098 173.311 500 179.213 500 186.499Z"
        fill="#FFC700"
      />
      <path
        d="M339.253 159.447C346.537 159.447 352.441 153.542 352.441 146.259C352.441 138.975 346.537 133.071 339.253 133.071C331.97 133.071 326.065 138.975 326.065 146.259C326.065 153.542 331.97 159.447 339.253 159.447Z"
        fill="#FFC700"
      />
      <path
        d="M392.128 170.296C392.128 175.693 387.752 180.062 382.362 180.062C376.971 180.062 372.595 175.687 372.595 170.296C372.595 164.905 376.971 160.53 382.362 160.53C387.752 160.53 392.128 164.905 392.128 170.296Z"
        fill="#B5EDE9"
      />
      <path
        d="M450.369 224.783C455.993 224.783 460.553 220.223 460.553 214.598C460.553 208.973 455.993 204.413 450.369 204.413C444.744 204.413 440.184 208.973 440.184 214.598C440.184 220.223 444.744 224.783 450.369 224.783Z"
        fill="#6222C3"
      />
      <path
        d="M413.15 223.084C413.15 227.527 409.55 231.127 405.106 231.127C400.663 231.127 397.063 227.527 397.063 223.084C397.063 218.641 400.663 215.041 405.106 215.041C409.55 215.041 413.15 218.641 413.15 223.084Z"
        fill="#B5EDE9"
      />
      <path
        d="M267.628 115.846C271.316 115.846 274.305 112.857 274.305 109.169C274.305 105.481 271.316 102.492 267.628 102.492C263.94 102.492 260.951 105.481 260.951 109.169C260.951 112.857 263.94 115.846 267.628 115.846Z"
        fill="#FFC700"
      />
      <path
        d="M471.335 260.746C475.022 260.746 478.012 257.757 478.012 254.069C478.012 250.382 475.022 247.392 471.335 247.392C467.647 247.392 464.658 250.382 464.658 254.069C464.658 257.757 467.647 260.746 471.335 260.746Z"
        fill="#FFC700"
      />
      <path
        d="M411.888 187.718C411.888 190.118 409.944 192.062 407.544 192.062C405.144 192.062 403.199 190.118 403.199 187.718C403.199 185.318 405.144 183.373 407.544 183.373C409.944 183.373 411.888 185.318 411.888 187.718Z"
        fill="#FFC700"
      />
      <path
        d="M473.631 124.572C477.318 124.572 480.308 121.583 480.308 117.895C480.308 114.208 477.318 111.218 473.631 111.218C469.943 111.218 466.953 114.208 466.953 117.895C466.953 121.583 469.943 124.572 473.631 124.572Z"
        fill="#B5EDE9"
      />
      <path
        d="M357.242 185.078C357.242 192.973 350.841 199.367 342.952 199.367C335.063 199.367 328.663 192.967 328.663 185.078C328.663 177.188 335.063 170.788 342.952 170.788C350.841 170.788 357.242 177.188 357.242 185.078Z"
        fill="#6222C3"
      />
      <path
        d="M301.826 163.607C304.551 163.607 306.761 161.397 306.761 158.671C306.761 155.945 304.551 153.736 301.826 153.736C299.1 153.736 296.89 155.945 296.89 158.671C296.89 161.397 299.1 163.607 301.826 163.607Z"
        fill="#6222C3"
      />
      <path
        d="M175.276 102.086C175.276 102.086 189.165 131.298 211.873 122.997C231.824 115.704 220.402 84.061 194.673 81.3102C168.943 78.5594 171.423 67.6916 155.46 70.4424C146.137 72.0485 138.235 77.2486 132.008 84.3748C125.558 91.7595 124.057 101.194 118.672 109.126C115.01 114.523 109.46 118.24 103.503 120.695C95.884 123.834 87.4347 122.357 82.2162 129.723C77.8284 135.914 76.8438 144.265 78.5238 151.545C79.1946 154.456 80.2346 157.348 80.0931 160.333C79.9207 163.939 77.9823 167.305 75.3422 169.773C68.4068 176.271 57.7912 176.413 49.0773 179.434C41.3357 182.118 33.6371 187.287 31.5078 195.201C29.1694 203.921 34.3756 211.853 34.2956 220.432C34.2279 227.854 26.6462 232.204 22.8308 237.743C17.3292 245.725 3.02134 282.322 45.3972 286.999C87.7732 291.676 89.9763 215.453 89.9763 215.453L140.334 182.708C140.334 182.708 183.19 126.782 175.282 102.086H175.276Z"
        fill="#213EAB"
      />
      <path
        d="M49.6927 200.469C52.3943 193.041 56.5174 187.022 64.7206 185.084C74.5545 182.758 94.4809 183.711 99.3548 173.157C102.327 166.72 101.933 160.179 103.509 153.52C105.847 143.668 115.73 145.453 124.007 139.243C127.3 136.775 127.872 131.618 128.488 127.871C129.312 122.825 129.731 117.871 133.091 113.735C134.826 111.6 137.06 109.889 139.552 108.726C145.355 106 152.162 106.332 157.712 102.966C161.325 100.775 163.866 97.3165 164.783 93.1934C165.313 90.818 165.442 88.1287 164.863 85.7472C172.808 85.7164 174.956 88.7441 178.107 93.9011C184.913 105.046 197.301 96.9965 203.492 107.932C208.593 116.948 221.812 111.354 221.812 106.104C220.433 95.1134 210.193 82.9656 194.685 81.3102C168.956 78.5594 171.436 67.6916 155.472 70.4424C146.155 72.0485 138.248 77.2486 132.02 84.3748C125.571 91.7595 124.069 101.194 118.684 109.126C115.023 114.523 109.472 118.24 103.515 120.695C95.8963 123.834 87.447 122.357 82.2285 129.723C77.8407 135.914 76.8561 144.265 78.5361 151.545C79.2069 154.456 80.2469 157.348 80.1054 160.333C79.9331 163.939 77.9946 167.305 75.3546 169.773C68.4191 176.271 57.8036 176.413 49.0896 179.434C41.348 182.118 33.6494 187.287 31.5202 195.201C29.4586 202.887 33.2494 209.964 34.1233 217.404C40.5664 213.552 46.4865 209.287 49.6927 200.469Z"
        fill="#6222C3"
      />
      <path
        d="M170.876 155.194C170.876 155.194 165.922 178.308 173.769 188.628C181.609 198.949 173.356 208.026 151.276 210.296C129.196 212.567 125.066 189.453 125.066 189.453C125.066 189.453 149.214 158.29 149.83 133.114C149.83 133.114 165.928 153.336 170.882 155.194H170.876Z"
        fill="#FFEDE5"
      />
      <path
        d="M146.907 175.957C149.442 162.72 149.024 149.268 152.328 136.191C150.802 134.332 149.83 133.114 149.83 133.114C149.208 158.29 125.066 189.453 125.066 189.453C125.066 189.453 126.15 195.429 130.1 201.004C136.919 193.324 144.962 186.148 146.913 175.964L146.907 175.957Z"
        fill="#6222C3"
      />
      <path
        d="M169.99 160.708C166.267 152.382 157.811 140.88 153.282 143.908C153.282 143.908 147.448 169.422 171.067 183.287C171.079 183.293 171.092 183.299 171.098 183.305C168.673 176.05 169.245 166.604 169.99 160.708Z"
        fill="#FFEDE5"
      />
      <path
        d="M207.381 124.191C207.381 124.191 204.107 153.545 197.498 163.034C190.894 172.53 177.891 176.038 166.543 167.164C155.196 158.29 154.783 147.969 152.303 140.542C149.829 133.114 144.875 134.695 142.949 126.437C141.023 118.184 145.977 115.157 149.688 115.846C149.688 115.846 156.291 117.36 156.291 126.714C156.291 136.068 164.956 131.803 167.848 128.505C170.734 125.2 175.553 114.332 175.276 102.092C175.276 102.092 187.245 125.692 207.375 124.197L207.381 124.191Z"
        fill="#FFEDE5"
      />
      <path
        d="M286.035 309.147C286.035 309.147 305.432 307.498 311.623 309.147C317.813 310.796 333.5 316.162 328.134 321.116C322.767 326.07 287.29 319.104 286.035 319.467C280.256 321.116 286.035 309.147 286.035 309.147Z"
        fill="#E8B1D2"
      />
      <path
        d="M194.298 202.758L204.852 206.346C210.581 214.106 216.674 230.075 219.578 251.361C224.532 287.682 227.006 288.919 229.898 291.811C232.785 294.697 289.333 308.415 289.333 308.415L286.447 325.245C286.447 325.245 214.169 319.879 207.381 319.055C200.593 318.23 182.021 270.765 182.433 251.78C182.846 232.795 194.298 202.764 194.298 202.764V202.758Z"
        fill="url(#paint0_linear_633_564)"
      />
      <g style={{ mixBlendMode: 'multiply' }} opacity="0.55">
        <path
          d="M280.662 318.113C272.206 317.313 264.102 315.362 255.837 313.485C244.612 310.938 233.098 311.024 222.12 307.454C207.72 302.778 202.864 282.888 205.627 269.996C205.824 269.072 207.615 256.869 201.467 239.337C199.873 234.795 197.252 230.217 196.372 225.466C195.356 219.995 193.627 214.635 193.116 209.269C188.882 220.69 182.716 239.349 182.445 251.78C182.033 270.765 200.606 318.23 207.393 319.055C214.181 319.879 286.459 325.245 286.459 325.245L287.696 318.045C285.444 318.298 283.105 318.341 280.674 318.113H280.662Z"
          fill="#F2EDF8"
        />
      </g>
      <path
        d="M204.852 206.346C204.852 206.346 186.446 195.435 172.218 186.093C172.218 186.093 159.319 196.672 157.257 197.287C155.196 197.909 132.697 176.234 132.697 176.234C132.697 176.234 115.226 187.361 109.638 188.696C99.2137 191.188 84.6104 194.604 84.6104 194.604C84.6104 194.604 102.358 227.417 93.2752 273.645C84.192 319.873 80.0688 335.559 80.0688 335.559H195.64C195.64 335.559 189.861 295.522 190.409 272.962C190.956 250.401 191.498 229.712 204.852 206.346Z"
        fill="url(#paint1_linear_633_564)"
      />
      <path
        d="M152.802 217.558C152.734 218.992 152.162 220.13 151.522 220.1C150.882 220.069 150.42 218.881 150.488 217.447C150.556 216.013 151.128 214.875 151.768 214.906C152.408 214.936 152.87 216.124 152.802 217.558Z"
        fill="#251839"
      />
      <path
        d="M151.104 242.678C151.036 244.112 150.464 245.251 149.824 245.22C149.184 245.189 148.722 244.001 148.79 242.568C148.857 241.134 149.43 239.995 150.07 240.026C150.71 240.057 151.171 241.244 151.104 242.678Z"
        fill="#251839"
      />
      <path
        d="M149.405 267.793C149.337 269.226 148.765 270.365 148.125 270.334C147.485 270.303 147.024 269.116 147.091 267.682C147.159 266.248 147.731 265.109 148.371 265.14C149.011 265.171 149.473 266.359 149.405 267.793Z"
        fill="#251839"
      />
      <path
        d="M147.7 292.907C147.633 294.341 147.06 295.479 146.42 295.448C145.78 295.417 145.319 294.23 145.386 292.796C145.454 291.362 146.026 290.224 146.666 290.254C147.306 290.285 147.768 291.473 147.7 292.907Z"
        fill="#251839"
      />
      <path
        d="M157.257 197.293C156.968 197.379 156.236 196.992 155.251 196.315C155.688 207.669 149.522 314.944 148.882 335.566H151.682C152.389 325.338 157.786 197.139 157.257 197.293Z"
        fill="#B6BCE0"
      />
      <g style={{ mixBlendMode: 'multiply' }} opacity="0.55">
        <path
          d="M80.075 335.559H128.783C128.556 335.043 128.316 334.532 128.131 333.984C124.685 323.652 128.069 313.738 120.999 303.688C117.214 298.31 111.386 291.94 110.943 284.95C110.451 277.202 112.5 270.593 116.894 264.094C120.125 259.312 125.528 253.017 126.377 247.251C128.162 235.164 120.266 228.352 114.143 220.53C106.371 210.61 106.032 200.093 107.546 189.201C97.3675 191.625 84.6104 194.604 84.6104 194.604C84.6104 194.604 102.358 227.417 93.2752 273.645C84.192 319.873 80.0688 335.559 80.0688 335.559H80.075Z"
          fill="#F2EDF8"
        />
      </g>
      <g style={{ mixBlendMode: 'multiply' }} opacity="0.55">
        <path
          d="M190.415 272.962C190.809 256.808 191.227 241.614 196.316 225.712C193.578 228.826 189.726 232.654 186.815 243.989C183.461 257.079 189.787 276.796 182.501 288.685C179.036 294.34 173.769 298.839 170.421 304.476C164.316 314.753 171.664 318.113 173.252 327.326C173.787 330.409 173.639 333.086 173.128 335.559H195.633C195.633 335.559 189.855 295.522 190.403 272.962H190.415Z"
          fill="#F2EDF8"
        />
      </g>
      <path
        d="M192.753 312.267C192.753 312.267 208.027 313.504 217.517 312.267C227.012 311.03 230.932 305.664 237.326 310.205C243.726 314.747 260.028 317.018 258.791 323.209C257.554 329.399 241.449 328.778 234.643 327.953C231.874 327.615 226.003 321.756 218.84 322.876C208.39 324.507 192.341 329.19 184.913 325.615C165.971 316.488 192.753 312.273 192.753 312.273V312.267Z"
        fill="#FFEDE5"
      />
      <path
        d="M197.295 308.415L194.341 325.756C194.341 325.756 105.115 332.323 68.7946 325.756C32.4741 319.19 32.868 316.175 35.7726 301.03C38.6834 285.885 62.4622 206.789 84.6163 194.61C84.6163 194.61 104.635 221.232 95.761 263.128C94.3764 269.676 82.3578 289.307 83.1024 291.128C85.1332 296.07 165.374 302.962 197.295 308.421V308.415Z"
        fill="url(#paint2_linear_633_564)"
      />
      <g style={{ mixBlendMode: 'multiply' }} opacity="0.55">
        <path
          d="M83.8658 292.962C84.4812 292.82 85.0966 292.704 85.712 292.587C84.2043 292.082 83.2935 291.596 83.0966 291.122C82.3519 289.307 94.3644 269.676 95.7552 263.122C97.2506 256.051 97.9152 249.429 98.0014 243.288C97.4906 244.672 96.9244 246.038 96.229 247.361C90.4382 258.359 82.2289 269.429 79.4781 281.713C78.6657 285.337 75.2072 294.581 83.8658 292.962Z"
          fill="#D4C1EE"
        />
      </g>
      <g style={{ mixBlendMode: 'multiply' }} opacity="0.55">
        <path
          d="M190.722 322.975C187.245 322.716 181.725 320.076 178.784 318.353C167.799 311.91 159.374 311.098 147.399 314.181C141.559 315.688 135.072 317.732 128.949 316.895C122.088 315.953 115.983 312.267 109.306 311.313C97.7981 309.67 84.8072 315.621 73.5024 312.187C56.5238 307.024 53.8776 284.254 60.3146 269.651C65.2254 258.519 73.7917 251.208 81.6318 242.198C86.8195 236.235 92.5365 228.949 94.5304 221.097C94.7704 220.149 94.9119 219.275 95.0473 218.407C91.0965 203.256 84.6226 194.616 84.6226 194.616C62.4746 206.795 38.6897 285.891 35.7789 301.036C32.8681 316.181 32.4743 319.196 68.8008 325.762C105.121 332.329 194.347 325.762 194.347 325.762L194.802 323.092C193.442 323.116 192.082 323.079 190.728 322.981L190.722 322.975Z"
          fill="#F2EDF8"
        />
      </g>
      <path
        d="M156.033 196.819L133.59 174.868C133.59 174.868 125.737 179.711 124.236 181.884C122.734 184.056 128.913 202.696 135.516 210.45C135.516 210.45 149.264 200.087 156.033 196.819Z"
        fill="url(#paint3_linear_633_564)"
      />
      <path
        d="M171.466 184.401C171.466 184.401 158.752 195.355 156.039 196.819C156.039 196.819 169.645 208.752 172.254 210.45C172.254 210.45 179.177 189.97 179.22 188.407C179.22 188.407 173.7 184.715 171.466 184.401Z"
        fill="url(#paint4_linear_633_564)"
      />
      <path
        d="M395.414 322.901H256.317V335.56H395.414V322.901Z"
        fill="url(#paint5_linear_633_564)"
      />
      <path
        d="M395.414 322.901H256.317V325.516H395.414V322.901Z"
        fill="#251839"
      />
      <path
        d="M257.554 322.901H197.294V335.56H257.554V322.901Z"
        fill="#251839"
      />
      <path
        d="M293.013 251.152L259.092 316.79C257.652 319.578 259.677 322.901 262.815 322.901H394.165C395.734 322.901 397.168 322.027 397.888 320.636L431.814 254.998C433.255 252.211 431.23 248.888 428.091 248.888H296.742C295.179 248.888 293.739 249.761 293.019 251.152H293.013Z"
        fill="#6222C3"
      />
      <path
        style={{ mixBlendMode: 'multiply' }}
        opacity="0.32"
        d="M402.928 301.627C384.349 303.461 365.045 304.581 346.398 302.956C321.444 300.778 300.318 287.46 283.382 269.78L259.086 316.79C257.646 319.578 259.671 322.901 262.809 322.901H394.159C395.728 322.901 397.162 322.027 397.882 320.636L408.042 300.974C406.35 301.208 404.664 301.454 402.928 301.627Z"
        fill="url(#paint6_linear_633_564)"
      />
      <path
        d="M263.769 316.79L297.69 251.152C298.41 249.761 299.844 248.888 301.413 248.888H296.736C295.173 248.888 293.733 249.761 293.013 251.152L259.092 316.79C257.652 319.578 259.677 322.901 262.815 322.901H267.492C264.354 322.901 262.329 319.578 263.769 316.79Z"
        fill="#251839"
      />
      <path
        d="M416.128 352.28H27.2432V429.616H416.128V352.28Z"
        fill="#EAFAF9"
      />
      <g style={{ mixBlendMode: 'multiply' }} opacity="0.75">
        <path
          d="M62.0005 376.163H416.128V352.28H27.2432V429.616L62.0005 376.163Z"
          fill="#B5EDE9"
        />
      </g>
      <path d="M439.987 335.559H0V354.957H439.987V335.559Z" fill="#089188" />
      <defs>
        <linearGradient
          id="paint0_linear_633_564"
          x1="251.985"
          y1="251.128"
          x2="196.68"
          y2="312.458"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EEEDF7" />
          <stop offset="0.4" stopColor="#E5E0F1" />
          <stop offset="1" stopColor="#D4CFE8" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_633_564"
          x1="169.922"
          y1="188.518"
          x2="115.017"
          y2="329.627"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EEEDF7" />
          <stop offset="0.4" stopColor="#E5E0F1" />
          <stop offset="1" stopColor="#D4CFE8" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_633_564"
          x1="142.685"
          y1="252.623"
          x2="88.7517"
          y2="332.969"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EEEDF7" />
          <stop offset="0.4" stopColor="#E5E0F1" />
          <stop offset="1" stopColor="#D4CFE8" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_633_564"
          x1="149.799"
          y1="182.191"
          x2="128.063"
          y2="197.601"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EEEDF7" />
          <stop offset="0.4" stopColor="#E5E0F1" />
          <stop offset="1" stopColor="#D4CFE8" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_633_564"
          x1="165.934"
          y1="185.798"
          x2="170.753"
          y2="205.195"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EEEDF7" />
          <stop offset="0.4" stopColor="#E5E0F1" />
          <stop offset="1" stopColor="#D4CFE8" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_633_564"
          x1="406.553"
          y1="329.233"
          x2="258.754"
          y2="329.233"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7B388C" />
          <stop offset="1" stopColor="#56348A" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_633_564"
          x1="420.301"
          y1="260.432"
          x2="275.007"
          y2="324.772"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7B388C" />
          <stop offset="0.41" stopColor="#56348A" />
          <stop offset="1" stopColor="#4A2C86" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default ValidatedLinkImage
