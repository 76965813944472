import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  Typography,
} from '@mui/material'
import React from 'react'
import InfoCardImage from '../InfoCardImage/InfoCardImage'

const InfoCard = () => {
  return (
    <Card sx={{ maxWidth: '450px' }}>
      <CardMedia component={InfoCardImage} />
      <CardHeader title="Vårt stöd för företag som riskerar att behöva ställa om." />
      <CardContent>
        <Typography>
          TRR erbjuder stöd till ert företag om ni står inför potentiell
          omställningssituation.
        </Typography>
      </CardContent>
      <CardActions>
        <Button size="small" variant="text" href={`../risk-for-omstallning/`}>
          Läs mer om stöd vid risk för omställning
        </Button>
      </CardActions>
    </Card>
  )
}

export default InfoCard
