/* eslint-disable @typescript-eslint/no-misused-promises */
import Visibility from '@mui/icons-material/Visibility'
import { Box, Button, TextField, Typography } from '@mui/material'
import { DesktopDatePicker as DatePicker } from '@mui/x-date-pickers'
import React, { useState } from 'react'
import { Controller, UseFormReturn } from 'react-hook-form'
import texts from './texts'
import { PreviewArbetsgivarinfoDialog } from './components'
import { FormValues } from 'src/types'

interface BaseFormProps {
  isError: boolean
  submitText: string
  CheckBoxes?: React.ReactNode
  DeactivateButton?: React.ReactNode
  formProps: UseFormReturn<FormValues>
}

const BaseForm = ({
  isError,
  submitText,
  CheckBoxes,
  DeactivateButton,
  formProps,
}: BaseFormProps) => {
  const [previewDialogOpen, setPreviewDialogOpen] = useState(false)
  const {
    register,
    control,
    formState: { errors },
    getValues,
    trigger,
  } = formProps

  const {
    endTimeHelperMessage,
    informationFieldHelperMessage,
    nameHelperMessage,
    startTimeHelperMessage,
    informationFieldErrorMessage,
    startTimeErrorMessage,
    endTimeErrorMessage,
    nameErrorMessage,
    endTimeBeforeStartTimeErrorMessage,
  } = texts

  const handlePreview = () => {
    setPreviewDialogOpen(true)
  }

  const fieldHasError = (field: keyof typeof errors) => {
    return Boolean(errors[field])
  }

  return (
    <>
      <TextField
        multiline
        fullWidth
        rows={5}
        sx={{ mb: 1 }}
        label="Information till medarbetarna"
        helperText={
          fieldHasError('text')
            ? errors.text?.message
            : informationFieldHelperMessage
        }
        {...register('text', {
          required: informationFieldErrorMessage,
        })}
        error={fieldHasError('text')}
      />
      <Button
        variant="text"
        startIcon={<Visibility />}
        sx={{ mb: 3 }}
        onClick={handlePreview}
      >
        Förhandsgranska
      </Button>
      <Box display="flex" gap={2} mb={3}>
        <Controller
          name="startTime"
          rules={{ required: startTimeErrorMessage }}
          control={control}
          render={({ field }) => {
            return (
              <DatePicker
                label="Ange startdatum"
                disablePast
                slotProps={{
                  textField: {
                    fullWidth: true,
                    helperText: fieldHasError('startTime')
                      ? errors.startTime?.message
                      : startTimeHelperMessage,
                    error: fieldHasError('startTime'),
                  },
                  popper: {
                    id: `startdate-popup-datepicker`,
                  },
                }}
                value={field.value}
                inputRef={field.ref}
                onChange={async (date) => {
                  field.onChange(date)
                  await trigger('endTime')
                }}
              />
            )
          }}
        />
        <Controller
          name="endTime"
          rules={{
            required: endTimeErrorMessage,
            validate: (value, formValues) => {
              const startTime = formValues.startTime
              if (startTime && value && new Date(value) < new Date(startTime)) {
                return endTimeBeforeStartTimeErrorMessage
              }
            },
          }}
          control={control}
          render={({ field }) => {
            return (
              <DatePicker
                label="Ange slutdatum"
                disablePast
                slotProps={{
                  textField: {
                    fullWidth: true,
                    helperText: fieldHasError('endTime')
                      ? errors.endTime?.message
                      : endTimeHelperMessage,
                    error: fieldHasError('endTime'),
                  },
                  popper: {
                    id: `enddate-popup-datepicker`,
                  },
                }}
                value={field.value}
                inputRef={field.ref}
                onChange={async (date) => {
                  field.onChange(date)
                  await trigger('endTime')
                }}
              />
            )
          }}
        />
      </Box>
      <TextField
        label="Länkens namn"
        helperText={
          fieldHasError('name') ? errors.name?.message : nameHelperMessage
        }
        fullWidth
        sx={{ mb: 3 }}
        {...register('name', {
          required: nameErrorMessage,
        })}
        error={fieldHasError('name')}
      />
      {CheckBoxes}
      <Box mt={2} display={'flex'} justifyContent={'space-between'}>
        <Button type="submit">{submitText}</Button>
        {DeactivateButton}
      </Box>
      {isError && (
        <Typography variant="body2" mt={1} color="error">
          Något gick fel. Var vänlig försök igen
        </Typography>
      )}

      <PreviewArbetsgivarinfoDialog
        open={previewDialogOpen}
        setOpen={setPreviewDialogOpen}
        body={getValues('text') ?? ''}
      />
    </>
  )
}

export default BaseForm
