import React from 'react'
import { BasePage } from 'src/features/MittForetagView/components'
import { UpdateLinkForm } from './components'
import { Box } from '@mui/material'

const UpdateLinks = () => {
  return (
    <BasePage
      mainComponent={<UpdateLinkForm />}
      sideComponent={<Box width={450} />}
    />
  )
}

export default UpdateLinks
