import { Card, Typography } from '@mui/material'
import React from 'react'

const NoActiveLinks = () => {
  return (
    <Card
      sx={{
        backgroundColor: 'surface.blue',
        padding: 3,
        mb: 2,
      }}
    >
      <Typography align="center">Du har inga aktiva länkar.</Typography>
    </Card>
  )
}

export default NoActiveLinks
