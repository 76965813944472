import React from 'react'
import { BasePage } from 'src/features/MittForetagView/components'
import { CreateLinkForm, CreateLinkGuide } from './components'

const CreateLink = () => {
  return (
    <BasePage
      mainComponent={<CreateLinkForm />}
      sideComponent={<CreateLinkGuide />}
    />
  )
}

export default CreateLink
