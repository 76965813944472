import { Card, CardContent, CardHeader, Typography } from '@mui/material'
import React from 'react'

const CreateLinkGuide = () => {
  return (
    <Card
      sx={{
        backgroundColor: 'surface.blue',
        width: 450,
      }}
    >
      <CardHeader title="Instruktioner" />
      <CardContent>
        <ol>
          <Typography component="li" mb={1}>
            Fyll i information till medarbetaren. T.ex. kan det vara bra att
            skriva lite om situationen och varför det är viktigt att de tar del
            av TRRs information och verktyg.
          </Typography>
          <Typography component="li" mb={1}>
            Du kan förhandsgranska vad medarbetarna kommer se innan du skapar
            länken.
          </Typography>
          <Typography component="li" mb={1}>
            Ange ett datum då länken ska bli aktiv. Du kan skapa länken innan
            den blir tillgänglig. Innan detta datum kommer länken inte att
            fungera.
          </Typography>
          <Typography component="li" mb={1}>
            Ange ett datum då länken ska sluta vara aktiv. Efter detta datum kan
            man inte längre komma åt länken.
          </Typography>
          <Typography component="li" mb={1}>
            Ge länken ett namn. Namnet används enbart för att underlätta för dig
            att hitta bland dina skapade länkar och är inte synligt för
            medarbetaren när du delar länken.
          </Typography>
          <Typography component="li">
            När du är redo trycker du på “skapa länk”.
          </Typography>
        </ol>
      </CardContent>
    </Card>
  )
}

export default CreateLinkGuide
