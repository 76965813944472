import React from 'react'
import ArbetsgivarInfoView from './features/ArbetsgivarinfoView'
import MittForetagView from './features/MittForetagView'

const App = () => {
  const isMittForetag = window.location.pathname.includes('/trr-foretag')

  // MF is set up in two places in Opti. Under /arbetsgivarinfo and as a sub page of /mitt-foretag
  // This is so we don't have to create a separate MF for the sole reason of authenticating a link and
  // displaying a link to LoginFlow as it is part of the same area of responsibility.
  return isMittForetag ? <MittForetagView /> : <ArbetsgivarInfoView />
}

export default App
